import request from '@/api/request'

//查询首页热招职位列表
export const getPositionByHot = (position_type1: string, city: Array<Number>) => {
  return request({
    url: `/jobs/getJobs?page=1&pageSize=9&field=view_total&order=descend&module=${position_type1}&city=[${city}]`,
    method: 'get'
  })
}

//查询首页精选职位列表
export const getPositionByBest = (position_type1: string) => {
  return request({
    url: `/jobs/getJobs?page=1&pageSize=9&field=id&order=descend&rateFlag=1`,
    method: 'get'
  })
}

//获取职位类型
export const getPositionType = () => {
  return request({
    url: '/dicts/getDictByType?type=module',
    method: 'get'
  })
}

//获取帖子分类
export const getPostCategory = () => {
  return request({
    url: '/dicts/getDictByType?type=postCategory',
    method: 'get'
  })
}

//查询首页搜索框热搜职位列表
export const getPositionHotSearchList = (cityName: string) => {
  return {
    code: 200,
    data: [
      "k","万","rate","急","远程","长期"
    ],
    msg: "请求成功"
  };
}

//---------轮播
export const selectSwiper = () => {
  return {
    code: 200,
    data: [
      {
        id :  1,
        imgUrl: "https://admin.saphub.cc/uploads/banner/WechatIMG2080.jpg",
        links: "123"
      },
      {
        id :  2,
        imgUrl: "https://admin.saphub.cc/uploads/banner/WechatIMG2080.jpg",
        links: "123"
      },
    ],
    msg: "请求成功"
  };
}


// 获取职位详情
export const getJobDetail = (position_id: string) => {
  return request({
    url: `/jobs/` + position_id,
    method: 'get'
  })
}


// 用户登陆
export const userLogin = (data: { username: string, password: string }) => {
  return request({
    url: '/login',
    method: 'post',
    data: data
  })
}

// 复制联系方式
export const copyContact = (data: { jobId: string, type: string }) => {
  return request({
    url: '/jobs/copyQQ',
    method: 'get',
    params: data
  })
}

//职位页面-查询职位列表
export const getPositionList = (data: any) => {
  return request({
    url: '/jobs/getJobs',
    method: 'get',
    params: data,
  })
}

//---------行业
export const selectIndustry = () => {
  return request({
    url: '/dicts/getDictByType?type=industry',
    method: 'get',
  })
}

//---------城市 
export const selectCity = () => {
  return request({
    url: '/dicts/getDictByType?type=city',
    method: 'get',
  })
}

//获取用户信息
export const getUser = (userId: string) => {
  return request({
    url: '/getUserInfo',
    method: 'get',
    data: {
      userId
    }
  })
}

// ------------------简历相关-------------
// hr查看在线简历
export const onlineResume = (userId: string) => {
  return request({
    url: `/consults/${userId}`,
    method: 'get',
  })
}
//修改用户consult的表
export const updateConsultByPc = (data: any) => {
  return request({
    url: '/consults/updateConsultByPc',
    method: 'post',
    data
  })
}

//添加用户工作经历
export const addUserWorkExp = (data: any) => {
  return request({
    url: '/workExps',
    method: 'post',
    data
  })
}
//修改用户工作经历
export const updateUserWorkExp = (data: any) => {
  return request({
    url: `/workExps/${data.id}`,
    method: 'put',
    data
  })
}
//添加用户教育经历
export const addUserEducation = (data: any) => {
  return request({
    url: '/educations',
    method: 'post',
    data
  })
}
//修改用户教育经历
export const updateUserEducation = (data: any) => {
  return request({
    url: `/educations/${data.id}`,
    method: 'put',
    data
  })
}

//添加用户项目经历
export const addUserProjectExp = (data: any) => {
  return request({
    url: '/projectExps',
    method: 'post',
    data
  })
}
//修改用户项目经历
export const updateUserProjectExp = (data: any) => {
  return request({
    url: `/projectExps/${data.id}`,
    method: 'put',
    data
  })
}

//修改用户user表
export const updateUser = (data: any) => {
  return request({
    url: `/users/${data.id}`,
    method: 'put',
    data
  })
}

//顾问自己上传简历
export const addUserResume = (data: any) => {
  return request({
    url: '/resumes',
    method: 'post',
    data
  })
}
//顾问删除自己的简历
export const delUserResume = (id: any) => {
  return request({
    url: `/resumes/${id}`,
    method: 'delete',
  })
}

//删除工作经历
export const delWorkExp = (id: any) => {
  return request({
    url: `/workExps/${id}`,
    method: 'delete',
  })
}
//删除项目经历
export const delProjectExp = (id: any) => {
  return request({
    url: `/projectExps/${id}`,
    method: 'delete',
  })
}
//删除教育经历
export const delEducation = (id: any) => {
  return request({
    url: `/educations/${id}`,
    method: 'delete',
  })
}

//获取pc端在线人数统计
export const pcOnlineSummary = () => {
  return request({
    url: '/home/pcOnlineSummary',
    method: 'get',
  })
}
//用户发送验证码
export const userSendCode = (params: any) => {
  return request({
    url: '/getAuthCode',
    method: 'get',
    params: params,
  })
}

//-----------帖子相关-----------
//查询帖子
export const selectPosts = (params:any) => {
  return request({
    url: '/posts' + params,
    method: 'get'
  })
}
// 新增帖子
export const addPost = (data:any) => {
  return request({
    url: '/posts',
    method: 'post',
    data
  })
}

//查询指定帖子
export const selectPostOne = (postId:string) => {
  return request({
    url: `/posts/${postId}`,
    method: 'get',
  })
}
// 新增评论
export const addComment = (data:any) => {
  return request({
    url: '/comments',
    method: 'post',
    data
  })
}
//查询所有评论
export const selectComments = (params:any) => {
  return request({
    url: '/comments' + params,
    method: 'get'
  })
}
// 帖子中上传图片
export const uploadFile = (data:any) => {
  return request({
    url: '/upload',
    method: 'post',
    data
  })
}
// ************以下暂未使用********


// hr登陆注册
export const hrLoginOrRegister = (data: { telephone: string, password: string }) => {
  return request({
    url: '/hr/LoginOrRegister',
    method: 'post',
    data: data
  })
}
//-------------投递相关
//添加投递记录
export const addSend = (data: Record<string, any>) => {
  return request({
    url: 'send',
    method: 'post',
    data
  })
}
//查询用户投递记录
export const selectSend = (data: any) => {
  return request({
    url: 'sends',
    method: 'get',
    params: data
  })
}
//取消/删除用户投递
export const cancelOrDelSend = (data: any) => {
  return request({
    url: 'send',
    method: 'delete',
    data
  })
}

//-------------收藏相关
//添加收藏
export const addCollect = (data: Record<string, any>) => {
  return request({
    url: 'collect',
    method: 'post',
    data
  })
}
//查询用户收藏
export const selectCollect = (data: any) => {
  return request({
    url: 'collects',
    method: 'get',
    params: data
  })
}
//删除用户收藏
export const deleteCollect = (data: any) => {
  return request({
    url: 'collect',
    method: 'delete',
    data
  })
}

//获取用户的收藏和投递状态
export const collectOrSendState = (data: any) => {
  return request({
    url: 'state',
    method: 'get',
    params: data
  })
}
//查询用户所有投递状态(数量)
export const allSendNum = (userId: string) => {
  return request({
    url: '/send/num',
    method: 'get',
    params: { userId }
  })
}

//随机排序查询职位列表  /position/by/rand?cityName=天津&num=6&isRandom=true
export const getSortOrRandomPosition = (data: { cityName: string, num?: number, isRandom?: boolean }) => {
  return request({
    url: `/position/by/rand`,
    method: 'get',
    params: data
  })
}